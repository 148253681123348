export const tab_list = [
  {
    title: '诊断',
    url: '/health-record/2',
  },
  {
    title: '检验',
    url: '/health-record/3',
  },
  {
    title: '检查',
    url: '/health-record/4',
  },
  {
    title: '用药',
    url: '/health-record/5',
  },
  // {
  //   title: '病史',
  //   url: '/health-record/medical-history',
  // },
]
