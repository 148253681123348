import api from '../../utils/api'

// 档案列表
export async function record_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_elements: 3,
  //         data: [
  //           {
  //             record_date: 1612108800000,
  //             records: [
  //               {
  //                 type: 2,
  //                 diag: {
  //                   doctor_name: '王浩',
  //                   diagnosis_name: '急性胃炎',
  //                   drugs: [
  //                     {
  //                       medi_name: '铝碳酸镁咀嚼片',
  //                       freq_name: '每日1次',
  //                       usage: '口服47.5mg',
  //                     },
  //                     {
  //                       medi_name: '铝碳酸镁咀嚼片2',
  //                       freq_name: '每日1次',
  //                       usage: '口服47.5mg',
  //                     },
  //                   ],
  //                   labs: [
  //                     {
  //                       report_name: '血常规',
  //                       count: 4,
  //                     },
  //                     {
  //                       report_name: '血气分析',
  //                       count: 8,
  //                     },
  //                   ],
  //                   exams: [
  //                     {
  //                       report_name: 'B超',
  //                       desc: '(1)双侧卵巢增大，可为正常的2-3双侧卵巢增大，可为正常的2',
  //                     },
  //                     {
  //                       report_name: '胸部ＣＴ扫描（平扫＋增强）',
  //                       desc: '(1)双侧卵巢增大，可为正常的2-3双侧卵巢增大，可为正常的2',
  //                     },
  //                   ],
  //                 },
  //               },
  //               {
  //                 type: 3,
  //                 labs: [
  //                   {
  //                     report_name: '血常规',
  //                     count: 4,
  //                     doctor_name: '王浩',
  //                   },
  //                   {
  //                     report_name: '血气分析',
  //                     count: 8,
  //                     doctor_name: '王浩',
  //                   },
  //                 ],
  //               }
  //             ],
  //           },
  //           {
  //             record_date: 1622476800000,
  //             records: [
  //               {
  //                 type: 4,
  //                 exams: [
  //                   {
  //                     report_name: 'B超',
  //                     desc: '(1)双侧卵巢增大，可为正常的2-3双侧卵巢增大，可为正常的2',
  //                   },
  //                   {
  //                     report_name: '胸部ＣＴ扫描（平扫＋增强）',
  //                     desc: '(1)双侧卵巢增大，可为正常的2-3双侧卵巢增大，可为正常的2',
  //                   },
  //                 ],
  //               }
  //             ],
  //           },
  //           {
  //             record_date: 1588262400000,
  //             records: [
  //               {
  //                 type: 5,
  //                 drugs: [
  //                   {
  //                     medi_name: '铝碳酸镁咀嚼片',
  //                     freq_name: '每日1次',
  //                     usage: '口服47.5mg',
  //                   },
  //                   {
  //                     medi_name: '铝碳酸镁咀嚼片2',
  //                     freq_name: '每日1次',
  //                     usage: '口服47.5mg',
  //                   },
  //                 ],
  //               }
  //             ],
  //           },
  //         ],
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/healthy/records`, {params: payload})
}

// 获取健康卡信息
export async function get_medical_cards_defaults () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '李明',
  //         gender_label: '男',
  //         birthday: 713059200000,
  //         type: 1,
  //         qr_code: ''
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/medical_cards/defaults`)
}
