<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="main_top">
      <div class="left">
        <img :src="get_user_head(user_info.type, user_info.gender)" class="head" />
        <div class="label">{{user_info.name}}</div>
        <div class="label">{{user_info.gender_label}}</div>
        <div class="label">{{format_birthDay(user_info.birthday)}}岁</div>
        <!-- <div class="btn">关注</div> -->
      </div>
      <!-- <div class="right">
        全程
        <div class="row"></div>
      </div> -->
    </div>
    <div class="tab_box">
      <div
        v-for="(item, index) in tab_list"
        :key="index"
        :class="['item', {selected: item.url === active_index}]"
        @click="switch_tab(item)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="g_main_content main_content">
      <router-view class="view-content" />
    </div>
  </div>
</template>
<script>
import { format_birthDay } from "../../utils/format"
import { get_user_head } from "../../utils/imgUrl"
import { tab_list } from "./config"
import { get_medical_cards_defaults } from "./service";

export default {
  data() {
    return {
      loading: false,
      tab_list,
      user_info: {},
    };
  },
  computed: {
    active_index () {
      return this.$route.path
    }
  },
  components: {

  },
  created() {
    this.get_info()
  },
  methods: {
    format_birthDay,
    get_user_head,
    switch_tab(item) {
      this.$router.replace({
        path: item.url
      })
    },
    async get_info () {
      this.loading = true
      try {
        const {data} = await get_medical_cards_defaults()
        this.user_info = data
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  background: #f7f7f7;
}
.main_top {
  height: 94px;
  background: url("../../assets/img/health_record_bg.png") 55vw -158px no-repeat;
  background-color: #000ca8;
  background-size: 213px 427px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  & > div {
    display: flex;
    align-items: center;
  }
  & .head {
    width: 44px;
    height: 44px;
    background: rgba(0, 0, 0, 0);
    border: 2px solid #ffffff;
    border-radius: 22px;
  }
  & .label {
    color: #fff;
    margin-left: 10px;
  }
  & .btn {
    padding: 5px 10px;
    font-weight: 500;
    color: #ffffff;
    background: #ff8900;
    border-radius: 24px;
    margin-left: 15px;
  }
  & .right {
    font-size: 18px;
    color: #ffffff;
    & .row {
      border-top: 6px solid #fff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      margin: 0 5px;
    }
  }
}
.tab_box {
  display: flex;
  justify-content: space-around;
  background: #fff;
  height: 48px;
  & .item {
    line-height: 40px;
    text-align: center;
    color: #565656;
    position: relative;
    &.selected:after {
      content: "";
      display: block;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 10px;
      height: 4px;
      background: #0088ff;
    }
  }
}
</style>
